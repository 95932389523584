import { FileSourceEnum } from '@systemeio/file-manager'
import {
  FormInput,
  FormInputPhone,
  FormSelect,
  Hint,
  Popover,
  RadioGroup,
  RadioGroupDataInterface,
  toast,
} from '@systemeio/ui-shared'
import SettingsLayout from 'modules/profile/components/settings-layout'
import { settingsMenuLocoKeys } from 'modules/profile/constants/settings-menu-loco-keys'
import { updateUserSettings } from 'modules/profile/user-settings/api/user-settings-api'
import UserSettingsEmail from 'modules/profile/user-settings/components/user-settings-email'
import UserSettingsSkeleton from 'modules/profile/user-settings/components/user-settings-skeleton'
import { UserSettingsLoco } from 'modules/profile/user-settings/constants/user-settings-loco'
import { CustomerTypeEnum } from 'modules/profile/user-settings/enums/customer-type-enum'
import { useUserSettings } from 'modules/profile/user-settings/hooks/use-user-settings'
import {
  EditableDataUserSettings,
  EditableUserSettings,
  UserSettingsErrorResponseContentInterface,
  UserSettingsErrorsInterface,
} from 'modules/profile/user-settings/types/user-settings-interface'
import { NextPage } from 'next'
import React, { useEffect, useState } from 'react'
import { AvatarUploader } from 'shared/components/image-uploader/avatar-uploader'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import InfoIcon from 'shared/icons/info-icon'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import { getBaseServerSideProps } from 'shared/utils/get-base-server-side-props'
import { getCountryData } from 'shared/utils/get-country-data'

const defaultErrors: UserSettingsErrorsInterface = {
  taxNumber: '',
  address: '',
  city: '',
  companyName: '',
  country: '',
  customerType: '',
  firstName: '',
  phone: '',
  state: '',
  lastName: '',
  postCode: '',
  companyNumber: '',
  displayName: '',
  profileImage: '',
}

const UserSettings: NextPage = () => {
  const { t } = useLocoTranslation()
  const { user, mutate: userDataMutate, isAffiliate, isCustomer } = useUser()
  const { userSettings, mutate } = useUserSettings()

  const customerTypeData: RadioGroupDataInterface<EditableUserSettings['customerType']>[] = [
    { id: 'personal', caption: t(CustomerTypeEnum.personal) },
    { id: 'company', caption: t(CustomerTypeEnum.company) },
  ]

  const [tempState, setTempState] = useState<EditableUserSettings | undefined>()

  const [errors, setErrors] = useState<UserSettingsErrorsInterface>(defaultErrors)

  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (userSettings && userSettings.user) {
      setTempState({
        companyNumber: userSettings.companyNumber,
        ...userSettings.user,
        displayName: userSettings.userDomainAccount.displayName,
        profileImage: userSettings.userDomainAccount.profileImage,
      })
    }
  }, [userSettings])
  const updateProfile = async () => {
    if (!tempState) return
    try {
      const isCompany = tempState.customerType === 'company'
      const data: EditableDataUserSettings = {
        companyNumber: isCompany ? tempState.companyNumber : null,
        user: {
          address: tempState.address,
          city: tempState.city,
          companyName: isCompany ? tempState.companyName : null,
          country: tempState.country || null,
          firstName: tempState.firstName,
          lastName: tempState.lastName,
          state: tempState.state,
          phone: tempState.phone,
          customerType: tempState.customerType,
          postCode: tempState.postCode,
          taxNumber: isCompany ? tempState.taxNumber : null,
        },
        userDomainAccount: {
          displayName: tempState.displayName,
          profileImage: tempState.profileImage?.id || null,
        },
      }
      setIsFetching(true)
      const response = await updateUserSettings(data)
      await mutate(response.data, false)
      const avatarUrl = response.data.userDomainAccount.profileImage?.path
      await userDataMutate(
        data =>
          data && {
            ...data,
            ...(avatarUrl ? { avatarUrl } : {}),
          },
        false,
      )
      setIsFetching(false)
      toast.success(t('global.changes_saved'))
      setErrors(defaultErrors)
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        const userSettingErrors = e.errors as unknown as UserSettingsErrorResponseContentInterface
        setErrors({
          firstName: userSettingErrors.fields.user?.firstName?.join(''),
          lastName: userSettingErrors.fields.user?.lastName?.join(''),
          phone: userSettingErrors.fields.user?.phone?.join(''),
          address: userSettingErrors.fields.user?.address?.join(''),
          city: userSettingErrors.fields.user?.city?.join(''),
          country: userSettingErrors.fields.user?.country?.join(''),
          postCode: userSettingErrors.fields.user?.postCode?.join(''),
          state: userSettingErrors.fields.user?.state?.join(''),
          taxNumber: userSettingErrors.fields.user?.taxNumber?.join(''),
          companyName: userSettingErrors.fields.user?.companyName?.join(''),
          customerType: userSettingErrors.fields.user?.customerType?.join(''),
          companyNumber: userSettingErrors.fields.user?.companyNumber?.join(''),
          displayName: userSettingErrors.fields.userDomainAccount?.displayName?.join(''),
          profileImage: userSettingErrors.fields.userDomainAccount?.profileImage?.join(''),
        })
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  return (
    <SettingsLayout
      titles={[{ caption: t(settingsMenuLocoKeys.profile), isStatic: true }]}
      onSave={updateProfile}
      isFetching={isFetching}
      disabled={!userSettings}
    >
      {tempState && userSettings ? (
        <div className="flex flex-col w-full h-fit rounded-md overflow-x-hidden gap-5 bg-white">
          <UserSettingsEmail email={userSettings.user?.email} mutate={mutate}>
            {(isAffiliate || isCustomer) && (
              <p className={'flex items-center'}>
                <InfoIcon className={'fill-blue inline mr-2'} />

                {t('dashboard.user.profile.data_used_in_invoice_hint')}
              </p>
            )}
          </UserSettingsEmail>
          <div className={'flex items-start flex-col px-5 gap-1'}>
            <Hint label={t('dashboard.user.profile_image_hint')}>
              <span className={'text-base text-darkblue font-bold'}>
                {t('dashboard.user.profile_image')}
              </span>
            </Hint>
            <AvatarUploader
              source={FileSourceEnum.Settings}
              onSetPhoto={dataFile =>
                setTempState(
                  prev =>
                    prev && {
                      ...prev,
                      profileImage: dataFile,
                    },
                )
              }
            >
              <img
                className={'object-cover border-blue border-2 rounded-full '}
                alt={userSettings.user?.email}
                width={128}
                height={128}
                src={tempState?.profileImage?.path || user?.avatarUrl}
              />
            </AvatarUploader>
          </div>
          <div className="flex flex-col gap-7 lg:gap-10 px-5 pb-5">
            <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 gap-7 lg:gap-10">
              <FormInput
                placeholder={t('dashboard.user.display_name')}
                label={
                  <div className={'flex gap-1.5 items-center'}>
                    {t('dashboard.user.display_name')}
                    <Popover
                      label={t('dashboard.user.display_name-info')}
                      popoverClassName={'w-[200px] sm:w-[350px]'}
                    >
                      {show => (
                        <QuestionMarkIcon
                          className={`${
                            show ? 'fill-blue' : 'fill-gray-100'
                          } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                        />
                      )}
                    </Popover>
                  </div>
                }
                required
                value={tempState.displayName || ''}
                onChange={e =>
                  setTempState(
                    prev =>
                      prev && {
                        ...prev,
                        displayName: e.target.value,
                      },
                  )
                }
                error={errors.displayName}
              />
              <FormInput
                placeholder={'John'}
                label={t('dashboard.user.first_name')}
                value={tempState.firstName || ''}
                onChange={e => setTempState(prev => prev && { ...prev, firstName: e.target.value })}
                error={errors.firstName}
              />
              <FormInput
                placeholder={'Doe'}
                label={t('dashboard.user.last_name')}
                value={tempState.lastName || ''}
                onChange={e => setTempState(prev => prev && { ...prev, lastName: e.target.value })}
                error={errors.lastName}
              />
            </div>
            <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 gap-7 lg:gap-10">
              <FormSelect
                placeholder={t('dashboard.user.select_country')}
                data={getCountryData(user?.dashboardLocale)}
                label={t('dashboard.user.country')}
                value={tempState.country || undefined}
                onChange={value =>
                  setTempState(prev => prev && { ...prev, country: value ?? null })
                }
                error={errors.country}
              />
              <FormInput
                placeholder={'San Francisco'}
                label={t('dashboard.user.city')}
                value={tempState.city || ''}
                onChange={e => setTempState(prev => prev && { ...prev, city: e.target.value })}
                error={errors.city}
              />
              <FormInput
                placeholder={t('dashboard.user.street_address')}
                label={t('dashboard.user.address')}
                value={tempState.address || ''}
                onChange={e => setTempState(prev => prev && { ...prev, address: e.target.value })}
                error={errors.address}
              />
            </div>
            <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 gap-7 lg:gap-10">
              <FormInput
                placeholder={t('dashboard.user.postal_code')}
                label={t('dashboard.user.postal_code')}
                value={tempState.postCode || ''}
                onChange={e => setTempState(prev => prev && { ...prev, postCode: e.target.value })}
                error={errors.postCode}
              />
              <FormInput
                placeholder={'California'}
                label={t('dashboard.user.state_province')}
                value={tempState.state || ''}
                onChange={e => setTempState(prev => prev && { ...prev, state: e.target.value })}
                error={errors.state}
              />
              <FormInputPhone
                label={t('dashboard.user.phone_number')}
                onChange={phone => setTempState(prev => prev && { ...prev, phone: phone })}
                value={tempState.phone || ''}
                error={errors.phone}
              />
            </div>
            <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 gap-7 lg:gap-10">
              <RadioGroup
                label={t('dashboard.user.customer_type')}
                selected={tempState.customerType}
                options={customerTypeData}
                onChange={value => setTempState(prev => prev && { ...prev, customerType: value })}
                error={errors.customerType}
              />
            </div>
            {tempState.customerType === 'company' && (
              <div className="flex flex-col gap-7 lg:gap-10">
                <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 gap-7 lg:gap-10">
                  <FormInput
                    placeholder={'Amazon'}
                    label={t('dashboard.user.company_name')}
                    required
                    value={tempState.companyName || ''}
                    onChange={e =>
                      setTempState(prev => prev && { ...prev, companyName: e.target.value })
                    }
                    error={errors.companyName}
                  />
                  <FormInput
                    placeholder={t('dashboard.user.company_number')}
                    label={t('dashboard.user.company_number')}
                    type={'number'}
                    value={tempState.companyNumber || ''}
                    onChange={e =>
                      setTempState(prev => prev && { ...prev, companyNumber: e.target.value })
                    }
                    error={errors.companyNumber}
                  />
                  <FormInput
                    placeholder={t('dashboard.user.tax_number')}
                    label={t('dashboard.user.tax_number')}
                    value={tempState.taxNumber || ''}
                    onChange={e =>
                      setTempState(prev => prev && { ...prev, taxNumber: e.target.value })
                    }
                    error={errors.taxNumber}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <UserSettingsSkeleton />
      )}
    </SettingsLayout>
  )
}

export const getServerSideProps = getBaseServerSideProps(UserSettingsLoco, 'global.settings')

export default UserSettings
