import {
  FileSelectorModal,
  FileSelectorModalProps,
  FileSourceEnum,
  FileTypeEnum,
} from '@systemeio/file-manager'
import { toast } from '@systemeio/ui-shared'
import React, { FC, ReactElement, useRef, useState } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import PenIcon from 'shared/icons/pen-icon'
import { DataFile } from 'shared/types/datafile-type'
import { fileInputAcceptTypes, isAcceptTypeValid } from 'shared/utils/file-input-accept-types'

interface AvatarUploaderProps {
  onSetPhoto: (file: DataFile) => void
  children: ReactElement
  source: FileSourceEnum
  width?: number
  height?: number
}

const initialSearchFilters = {
  name: '',
  source: [],
  type: [FileTypeEnum.Image, FileTypeEnum.ImageOther],
}

export const AvatarUploader: FC<AvatarUploaderProps> = ({
  onSetPhoto,
  children,
  width,
  height,
  source,
}: AvatarUploaderProps) => {
  const { t, i18n } = useLocoTranslation()
  const [isOpened, setIsOpened] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const uploaderRef = useRef() as NonNullable<
    FileSelectorModalProps['fileUploaderModalProps']
  >['uploaderRef']
  const fileSelectorRef = useRef() as FileSelectorModalProps['fileSelectorRef']

  const onAddFile = (file: File) => {
    setIsOpened(true)
    setTimeout(() => {
      fileSelectorRef?.current.setFileUploadModalOpen(true)
      uploaderRef?.current?.setWaitingUploadFiles(prev => [...prev, { file }])
    })
  }

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const { files } = e.dataTransfer
    if (files && files.length) {
      const file = files[0]
      if (!isAcceptTypeValid(file.type, fileInputAcceptTypes.image)) {
        toast.error(t('dashboard.file_upload.file_not_supported'))
      } else {
        onAddFile(file)
      }
    }
    setIsDragging(false)
  }
  return (
    <>
      <label>
        <input
          type="file"
          className="invisible h-1 absolute w-0"
          onClick={e => {
            e.preventDefault()
            setIsOpened(true)
          }}
        />
        <div
          className={`rounded-full relative cursor-pointer ${isDragging && 'ring-2 ring-blue'}`}
          style={{
            width: width,
            height: height,
          }}
          onDragStart={handleDrag}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onDragOver={handleDrag}
        >
          {children}
          <div
            className={
              'absolute bottom-0 right-0 translate-x-1/3 rounded-full bg-blue w-5 h-5 p-1 flex items-center justify-center'
            }
          >
            <PenIcon className={'fill-white w-4 h-4'} />
          </div>
        </div>
      </label>

      <FileSelectorModal
        opened={isOpened}
        uploadingFromSource={source}
        fileSelectorRef={fileSelectorRef}
        disabledFileTypeSelect
        initialSearchFilters={initialSearchFilters}
        fileUploaderModalProps={{
          uploaderRef,
          imageCropperModalProps: {
            isRounded: true,
            imageCropperProps: {
              aspectRatio: 1,
            },
          },
        }}
        onSelect={file => {
          setIsOpened(false)
          onSetPhoto(file)
        }}
        onClose={() => setIsOpened(false)}
      />
    </>
  )
}
